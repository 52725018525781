.features-aside-header {
  max-width: rem(230);

  &__text {
    white-space: pre-wrap;

    @include media-max(xl) {
      font-size: rem(14px);
      line-height: 1.22;
      margin-right: rem(30px);

      max-width: rem(130px);
    }

    @include media-max(sm) {
      font-size: rem(16px);

      max-width: none;
    }
  }
}
