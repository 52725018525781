.features {
  // $inner_padding-top: rem(60px) + $header_height;
  // $inner_padding-bottom: rem(110px);

  // $inner_max-xl_padding-top: rem(45px) + $header_height;
  // $inner_max-xl_padding-bottom: rem(124px);

  // $inner_max-sm_padding-top: rem(40px) + $header_height;
  // $inner_max-sm_padding-bottom: rem(60px);
  $this: &;

  $this_padding-bottom: rem(40px);
  $this_padding-bottom_min-md: rem(105px);
  $this_padding-bottom_min-xl: rem(90px);

  $inner_padding-top: rem(40px) + $header_height;
  $inner_padding-bottom: rem(20px);

  $inner_padding-top_min-md: rem(45px) + $header_height;
  $inner_padding-top_min-xl: rem(40px) + $header_height;

  padding-bottom: $this_padding-bottom;

  &--all {
    height: auto !important;
  }

  &--all &-aside__bottom {
    display: none;
  }

  &--all &-main__body {
    & {
      display: none;
    }

    &--mobile {
      display: block;
    }
  }

  &__inner {
    position: sticky;
    top: 0;

    min-height: 100vh;
    padding-top: $inner_padding-top;
    padding-bottom: $inner_padding-bottom;

    overflow: hidden;

    align-content: flex-start;
  }

  @include media-max(sm) {
    height: auto !important;
  }

  @include media-min(sm) {
    &__inner {
      align-content: stretch;
    }
  }

  @include media-min(md) {
    padding-bottom: $this_padding-bottom_min-md;

    &__inner {
      padding-top: $inner_padding-top_min-md;
    }
  }

  @include media-min(xl) {
    padding-bottom: $this_padding-bottom_min-xl;

    &__inner {
      padding-top: $inner_padding-top_min-xl;
    }
  }

  @media (orientation: landscape) and (max-height: 414px) {
    position: initial;
    height: auto !important;
  }
}
