.copyright-author {
  // variables
  $link_color: $color-gray-chateau;
  $link_font-size: rem(15px);
  $link_font-family: $font-family-anonymous-pro;
  $link_line-height: 1.24;

  // styles
  &__link {
    color: inherit;
    display: inline-flex;
    align-items: baseline;

    text-decoration: none;
    white-space: pre-wrap;
    font-family: $link_font-family;
    font-size: $link_font-size;
    line-height: $link_line-height;
  }

  &__link-logo {
    display: block;
    max-width: rem(100);
  }

  &__link-made-in {
    // margin-bottom: em(3px);
    color: $link_color;
  }

  @include media-min(xs) {
    &__link {
      flex-direction: column;
    }
  }

  @include media-min(md) {
    &__link {
      flex-direction: row;
    }
  }
}
