.product {
  @include media-min(xl) {
    &_category-block-containers {
      .control-cabinets-slider-slide {
        &__image-wrapper {
          margin-top: auto;
          margin-left: auto;
          transform: translate(25%, 10%) scale(1.5);
        }
      }
    }

    &_category-pumping-stations {
      .control-cabinets-slider-slide {
        &__image-wrapper {
          transform: translate(-15%, 20%) scale(1.2);
          margin-top: auto;
          margin-right: auto;
        }
      }
    }

    &_category-electronics {
      .control-cabinets-slider-slide {
        &__image-wrapper {
          transform: translate(20%, 20%) scale(1.3);
        }
      }
    }
  }
}
