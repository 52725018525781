.control-cabinets-description {
  $this: &;

  $header_margin: rem(48px) 0 rem(120px) 0;
  $header_max-xl_margin-bottom: rem(87px);
  $header_max-sm_margin: rem(42px) 0 rem(95px);

  $header-children_margin-right: rem(15px);

  $card_padding-bottom: $card_padding-y -
    $control-cabinets-description-download-link_padding-y;

  display: flex;
  flex-direction: column;

  &__item {
    overflow: hidden;

    display: none;

    &_active {
      display: block;
    }
  }

  &__header {
    margin: $header_margin;

    @include media-max(xl) {
      margin-bottom: $header_max-xl_margin-bottom;
    }

    @include media-max(sm) {
      margin: $header_max-sm_margin;
    }
  }

  &__header-inner {
    align-items: flex-start;

    margin-left: rem(-10px);
    margin-right: rem(-10px);
  }

  &__header-inner > * {
    padding-left: rem(10px);
    padding-right: rem(10px);
  }

  &__card {
    padding-bottom: $card_padding-bottom;

    &:not(:last-child) {
      @include media-max(sm) {
        border-bottom-color: transparent;
      }

      @include media-min(sm) {
        border-right-color: transparent;
      }
    }
  }

  @include media-min(xl) {
    &__header-inner {
      justify-content: space-between;
    }
  }

  @include media-min(xxl) {
    &__header-col-with-title {
      max-width: 20%;
    }

    &__header-col-with-primary-description {
      max-width: 40%;
    }
  }
}
