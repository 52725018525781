.control-cabinets-slider-slide {
  $this_padding-y: $header_height + rem(15px);
  $this_padding-x: rem(30px);

  position: relative;

  //padding: $this_padding-y $this_padding-x;

  display: flex;

  align-items: flex-end;
  justify-content: center;

  overflow: hidden;

  // убирает появление линии заднего фона другого слайда на активном слайде
  // при анимации появления страницы
  backface-visibility: hidden;

  &__image-wrapper {
    transform: translateY(rem(85px));
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__image {
    margin: 0 auto;
    // max-height: 100%;
    height: 100%;
    // height: auto;
    width: auto;
  }

  @include media-min(xl) {
    align-items: center;

    &__image-wrapper {
      transform: translateY(0);
    }

    &__image {
      margin-bottom: 0;
      max-height: rem(740px);
      height: auto;
    }
  }
}
