.home-hero {
  $this: &;
  $this_min-height: rem(568px);

  $container_padding-top: $header_height + rem(42px);
  $container_padding-top_min-md: $header_height + rem(68px);
  $container_padding-top_min-xl: $header_height + rem(80px);

  $container_padding-bottom: rem(11px);
  $container_padding-bottom_min-md: rem(19px);
  $container_padding-bottom_min-xl: rem(12px);

  position: relative;

  flex: 0 0 auto;
  height: 100vh;

  user-select: none;
  overflow: hidden;

  &__container {
    position: relative;
    padding-top: $container_padding-top;
    padding-bottom: $container_padding-bottom;
  }

  &__nav {
    display: none;
  }

  &__container,
  &__inner,
  &__body {
    height: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__description-container {
    overflow: hidden;
    display: none;
    align-self: flex-start;
  }

  &__description {
    max-width: rem(230);
  }

  &__footer {
    margin-top: auto;
  }

  &__footer-inner {
    justify-content: flex-end;
    align-items: baseline;

    overflow: hidden;
  }

  &__next-to-btn {
    display: none;
  }

  &.theme_light {
    #{$this}__description,
    #{$this}__link,
    #{$this}__pagination .pagination__total,
    #{$this}__next-to-btn .next-to-btn__dir {
      color: $theme-light-text-secondary-color;
    }

    #{$this}__link.active,
    #{$this}__link:hover,
    #{$this}__title,
    #{$this}__sub-nav-link,
    #{$this}__pagination .pagination__current-container:after,
    #{$this}__pagination .pagination__current,
    #{$this}__next-to-btn .next-to-btn__button {
      color: $theme-light-text-primary-color;
    }
  }

  &.theme_changing {
    #{$this}__title,
    #{$this}__sub-nav-link,
    #{$this}__pagination .pagination__current,
    #{$this}__next-to-btn .next-to-btn__button {
      transition: all 0s 0.5s;
    }

    #{$this}__description,
    #{$this}__link,
    #{$this}__link.active,
    #{$this}__pagination .pagination__current-container:after,
    #{$this}__pagination .pagination__total,
    #{$this}__next-to-btn .next-to-btn__dir {
      transition-duration: 0.4s;
      transition-property: all;
    }
  }

  &.theme_changing.theme_dark {
    #{$this}__description {
      transition-delay: 0.1s;
    }

    #{$this}__pagination .pagination__current-container:after,
    #{$this}__pagination .pagination__total {
      transition-delay: 0s;
    }
  }

  &.theme_changing.theme_light {
    #{$this}__description {
      transition-delay: 0s;
    }

    #{$this}__pagination .pagination__current-container:after,
    #{$this}__pagination .pagination__total {
      transition-delay: 0.2s;
    }
  }

  @include media-min(375px) {
    &__description-container {
      display: block;
    }
  }

  @include media-min(sm) {
    &__nav {
      display: block;
    }

    &.theme_changing.theme_dark {
      #{$this}__description {
        transition-delay: 0.3s;
      }

      #{$this}__pagination .pagination__current-container:after,
      #{$this}__pagination .pagination__total {
        transition-delay: 0s;
      }
    }

    &.theme_changing.theme_light {
      #{$this}__description {
        transition-delay: 0s;
      }

      #{$this}__link,
      #{$this}__link.active,
      #{$this}__pagination .pagination__current-container:after,
      #{$this}__pagination .pagination__total {
        transition-delay: 0.4s;
      }
    }
  }

  @include media-min(md) {
    &__container {
      padding-top: $container_padding-top_min-md;
      padding-bottom: $container_padding-bottom_min-md;
    }
  }

  @include media-min(lg) {
    &__body-inner {
      height: 100%;

      align-items: flex-start;
    }

    // &__description-container {
    //   text-align: right;
    // }

    &__footer-inner {
      justify-content: unset;
    }

    &__next-to-btn {
      display: block;
    }

    &.theme_changing.theme_dark {
      #{$this}__description {
        transition-delay: 0s;
      }

      #{$this}__link,
      #{$this}__link.active,
      #{$this}__pagination .pagination__current-container:after,
      #{$this}__pagination .pagination__total,
      #{$this}__next-to-btn .next-to-btn__dir {
        transition-delay: 0.5s;
      }
    }

    &.theme_changing.theme_light {
      #{$this}__description {
        transition-delay: 0.5s;
      }

      #{$this}__link,
      #{$this}__link.active,
      #{$this}__pagination .pagination__current-container:after,
      #{$this}__pagination .pagination__total,
      #{$this}__next-to-btn .next-to-btn__dir {
        transition-delay: 0s;
      }
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $container_padding-top_min-xl;
      padding-bottom: $container_padding-bottom_min-xl;
    }
  }

  @media (orientation: landscape) and (max-height: 414px) {
    &__container {
      padding-top: $header_height + rem(10px);
    }

    &__description {
      display: none;
    }
  }
}
