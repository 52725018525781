*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: $font-size-base;
  // фикс проблемы из-за которой на главной возникали проблему со слайдом
  // (появлялась часть след. секции при смене слайдов в обратном направлении)
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

a,
button {
  &:focus {
    outline: none;
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  display: block;
  max-width: 100%;
}

// body {
//   height: 100vh !important;
// }
