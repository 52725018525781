.footer {
  $this_padding_top: rem(75px);
  $this_max-xl_padding-top: rem(67px);
  $this_max-sm_padding-top: rem(60px);

  $header_margin-bottom: rem(80px);
  $nav_margin-bottom: rem(65px);
  $main_margin-bottom: rem(95px);

  $header_max-sm_margin-bottom: rem(60px);

  $main_max-xl_margin-bottom: rem(51px);
  $main_max-sm_margin-bottom: rem(35px);

  $title-container_margin-bottom: rem(8px);
  $title-container_min-md_margin-bottom: rem(10px);

  $title_font-size: rem(25px);

  $title-accent_font-size: rem(30px);
  $title-accent_font-size_min-md: rem(40px);
  $title-accent_font-size_min-xl: rem(60px);

  $title-accent_line-height_min-md: $line-height-xs;
  $title-accent_line-height_min-xl: 0.98;

  $title-accent_letter-spacing_min-md: -0.03em;

  $subtitle_font-family: $font-family-tt-commons;
  $subtitle_line-height: $line-height-sm;
  $subtitle_font-size: rem(20px);

  // position: relative;
  // transform: translateZ(0);
  padding-top: $this_padding_top;
  // margin-top: auto;

  // $navs_gap: rem(30px);
  // $navs_gap-half: $navs_gap / 2;

  // &__navs {
  //   margin-left: -$navs_gap-half;
  //   margin-right: -$navs_gap-half;
  // }

  // &__nav {
  //   padding-left: $navs_gap-half;
  //   padding-right: $navs_gap-half;
  // }

  &__header {
    margin-bottom: $header_margin-bottom;
  }

  &__navs > &__nav:not(:last-child) {
    margin-bottom: $nav_margin-bottom;
  }

  &__main {
    margin-bottom: $main_margin-bottom;
  }

  &__title {
    font-size: $title_font-size;
    text-transform: uppercase;
    white-space: pre-line;

    &_accent {
      font-size: $title-accent_font-size;
    }
  }

  &__title-container {
    margin-bottom: $title-container_margin-bottom;
  }

  &__subtitle {
    font-family: $subtitle_font-family;
    line-height: $subtitle_line-height;
    font-size: $subtitle_font-size;
  }

  &__copyright .copyright-author__link {
    align-items: baseline;
  }

  &__contact {
    vertical-align: bottom;
  }

  @include media-min(414px) {
    &__copyright .copyright__right {
      margin-bottom: $copyright-right_margin-bottom;

      text-align: left;

      order: 0;
    }

    &__copyright .copyright__left {
      order: 1;
    }

    &__copyright .copyright-author__link {
      flex-direction: row;
    }
  }

  @include media-min(sm) {
    &__copyright .copyright__right {
      margin: 0;

      order: 1;
    }

    &__copyright .copyright__left {
      order: 0;
    }
  }

  @include media-min(md) {
    &__copyright .copyright-main {
      flex-direction: column;
    }

    &__copyright .copyright-main__policy {
      order: 1;
    }

    &__copyright .copyright-main__copyright {
      order: 0;
    }

    &__title-container {
      margin-bottom: $title-container_min-md_margin-bottom;
    }

    &__title_accent {
      font-size: $title-accent_font-size_min-md;
      line-height: $title-accent_line-height_min-md;
      letter-spacing: $title-accent_letter-spacing_min-md;
    }
  }

  @include media-min(xl) {
    &__title_accent {
      font-size: $title-accent_font-size_min-xl;
      line-height: $title-accent_line-height_min-xl;
    }

    &__title {
      white-space: normal;
    }

    &__copyright .copyright-main {
      flex-direction: row;
    }

    &__copyright .copyright-main__policy {
      order: 0;
    }

    &__copyright .copyright-main__copyright {
      order: 1;
    }
  }

  @include media-max(xl) {
    padding-top: $this_max-xl_padding-top;

    &__navs > &__nav:not(:last-child) {
      margin-bottom: 0;
      padding-right: rem(15px);
    }

    &__main {
      margin-bottom: $main_max-xl_margin-bottom;
    }
  }

  @include media-max(sm) {
    padding-top: $this_max-sm_padding-top;

    &__header {
      margin-bottom: $header_max-sm_margin-bottom;
    }

    &__main {
      margin-bottom: $main_max-sm_margin-bottom;
    }
  }
}
