.facility-list-item {
  $this_margin-bottom: rem(60px);
  $this_margin-bottom_min-lg: rem(20px);
  $this_margin-bottom_min-xl: rem(15px);

  $header_margin-bottom: rem(21px);
  $name-container_margin-bottom: rem(6px);

  $place-container_margin-bottom: rem(21px);
  $place-container_margin-bottom_min-sm: rem(27px);

  $image_min-xl_max-width: rem(340px);

  $image-wrapper_min-height: rem(130px);
  $image-wrapper_min-height_min-md: rem(140px);
  $image-wrapper_min-height_min-xl: rem(115px);
  $image-wrapper_min-height_min-1600: rem(180px);

  $inner_min-sm_height: rem(142px);
  $inner_man-xl_height: rem(115px);

  $name_font-size: rem(20px);
  $name_font-family: $font-family-gl-interstate;
  $name_line-height: $line-height-sm;

  $place_font-size: rem(14px);
  $place_font-family: $font-family-anonymous-pro;
  $place_line-height: $line-height-sm;

  $link-icon_size: em(18px);
  $link-icon_color: $primary-color;

  $logo-container_margin-bottom: rem(27px);

  position: relative;
  background-color: $background-color-base;

  transition: background-color 0.4s;

  &:hover {
    background-color: #eaeaea;
  }

  &:not(:last-child) &__container {
    padding-bottom: $this_margin-bottom;
  }

  &__image {
    transition: transform 0.4s;
  }

  &:hover &__image {
    transform: scale(1.2) !important;
  }

  &__line {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: $block-divider_weight;

    background-color: $block-divider_color;
    z-index: 1;
  }

  &__logo-container {
    mix-blend-mode: darken;
  }

  &__logo {
    max-height: 60px;
  }

  &__header {
    margin-bottom: $header_margin-bottom;
    height: 100%;
  }

  &__header-inner {
    height: 100%;
  }

  &__body-inner {
    align-items: flex-start;
  }

  &__name-container {
    margin-bottom: $name-container_margin-bottom;
  }

  &__place-container {
    margin-bottom: $place-container_margin-bottom;
  }

  &__link-container {
    display: flex;
    justify-content: flex-end;
  }

  &__link {
    display: inline-block;
  }

  &__link-inner {
    display: flex;
    align-items: flex-start;
  }

  &__link-icon {
    width: $link-icon_size;
    height: $link-icon_size;
    color: $link-icon_color;
  }

  &__header {
    height: auto;
    min-height: $image-wrapper_min-height;
  }

  &__image-wrapper {
    height: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    will-change: transform;
  }

  &__name {
    font-family: $name_font-family;
    font-size: $name_font-size;
    line-height: $name_line-height;
    white-space: pre-line;
  }

  &__place {
    font-family: $place_font-family;
    font-size: $place_font-size;
    line-height: $place_line-height;
    white-space: pre-line;
  }

  @include media-min(sm) {
    &:not(:last-child) &__container {
      padding-bottom: 0;
    }

    &__container {
      padding-top: $this_margin-bottom_min-lg;
    }

    &__line {
      bottom: -1px;
      top: unset;
    }

    &__header {
      margin-bottom: 0;
    }

    &__logo-container {
      margin-bottom: $logo-container_margin-bottom;
    }

    &__place-container {
      margin-bottom: $place-container_margin-bottom_min-sm;
    }
  }

  @include media-min(md) {
    &__header {
      min-height: $image-wrapper_min-height_min-md;
    }
  }

  @include media-min(lg) {
    &__inner {
      position: relative;
    }

    &__logo-container {
      margin-bottom: 0;
    }

    &__logo {
      max-height: 100px;
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $this_margin-bottom_min-xl;
      max-width: none;
    }

    &__header {
      min-height: $image-wrapper_min-height_min-xl;
    }
  }

  @include media-min(1600px) {
    &__header {
      min-height: $image-wrapper_min-height_min-1600;
    }
  }
}
