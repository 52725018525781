$home-hero-title-link_font-size: rem(30px);
$home-hero-title-link_font-size_min-sm: rem(60px);
$home-hero-title-link_font-size_min-xl: rem(70px);

.home-hero-title-link {
  $this_font-size: $home-hero-title-link_font-size;
  $this_font-size_min-sm: $home-hero-title-link_font-size_min-sm;
  $this_font-size_min-xl: $home-hero-title-link_font-size_min-xl;

  $this-icon_margin-left: em(1px);

  display: flex;
  align-items: flex-end;

  height: 2em;

  vertical-align: bottom;
  font-size: $this_font-size;

  .light & {
    color: $theme-light-text-primary-color;
  }

  &__container {
    display: inline-block;
    overflow: hidden;
  }

  &__inner {
    white-space: nowrap;
    display: inline-block;
  }

  &__label {
    white-space: pre-wrap;
  }

  &__icon {
    margin-left: -$this-icon_margin-left;
  }

  @include media-min(sm) {
    font-size: $this_font-size_min-sm;
  }

  @include media-min(xl) {
    font-size: $this_font-size_min-xl;
  }

  @include media-min(xxl) {
    font-size: vw(70 / 1440);
  }

  @media (orientation: landscape) and (max-height: 414px) {
    font-size: rem(36px);
  }
}
